// API enums
export enum ResponseStatusType {
  OK = "OK",
  TIMEOUT = "TIMEOUT",
  CANNOT_CONNECT = "CANNOT_CONNECT",
  SERVER = "SERVER",
  UNAUTHORIZED = "UNAUTHORIZED",
  FORBIDDEN = "FORBIDDEN",
  NOT_FOUND = "NOT_FOUND",
  REJECTED = "REJECTED",
  UNKNOWN = "UNKNOWN",
  BAD_DATA = "BAD_DATA",
  TOO_MANY_REQUESTS = "TOO_MANY_REQUESTS",
  CONFLICT = "CONFLICT"
}
