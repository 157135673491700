// Enums
import { ResponseStatusType } from "../../../enums/api";

export type FileUploadResponseType = {
  url: string;
  statusType: ResponseStatusType;
};

export class FileUploadResponse {
  url: string;
  statusType: ResponseStatusType;

  constructor(responseData: FileUploadResponseType) {
    this.url = responseData.url;
    this.statusType = ResponseStatusType.OK;
  }
}
