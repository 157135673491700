type BranchSearchType = {
  name: string;
  address: string;
};

export class BranchSearchRequest {
  name?: string;
  address?: string;

  constructor(data?: BranchSearchType) {
    this.name = data?.name || "";
    this.address = data?.address || "";
  }
}
