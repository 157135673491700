// Types
import { SmallTextProps } from "./all-typography.types";

// Styles
import { SmallTextStyles } from "./all-typography.styles";

const SmallText = ({ children, className, style }: SmallTextProps) => (
  <SmallTextStyles className={className} style={{ ...style }}>
    {children}
  </SmallTextStyles>
);

export default SmallText;
