import {
  ApiResponse,
  UNKNOWN_ERROR,
  CONNECTION_ERROR,
  NETWORK_ERROR,
  TIMEOUT_ERROR,
  SERVER_ERROR,
  CLIENT_ERROR
} from "apisauce";
// API enums
import { ResponseStatusType } from "../../enums/api";
// types
import { GeneralApiProblem } from "./general-api-problems.types";

/**
 * Attempts to get a common cause of problems from an api response.
 *
 * @param response The api response.
 */
export function getGeneralApiProblem(
  response: ApiResponse<any>
): GeneralApiProblem {
  switch (response.problem) {
    case CONNECTION_ERROR:
      return {
        statusType: ResponseStatusType.CANNOT_CONNECT,
        message: response.data.error_message
      };
    case NETWORK_ERROR:
      return {
        statusType: ResponseStatusType.CANNOT_CONNECT,
        message: response.data.error_message
      };
    case TIMEOUT_ERROR:
      return {
        statusType: ResponseStatusType.TIMEOUT,
        message: response.data.error_message
      };
    case SERVER_ERROR:
      return {
        statusType: ResponseStatusType.SERVER,
        message: response.data.error_message
      };
    case UNKNOWN_ERROR:
      return {
        statusType: ResponseStatusType.UNKNOWN,
        message: response.data.error_message
      };
    case CLIENT_ERROR:
      switch (response.status) {
        case 400:
          return {
            statusType: ResponseStatusType.BAD_DATA,
            message: response.data.error_message,
            error: response.data
          };
        case 401:
          return {
            statusType: ResponseStatusType.UNAUTHORIZED,
            message: response.data.error_message
          };
        case 403:
          return {
            statusType: ResponseStatusType.FORBIDDEN,
            message: response.data.error_message
          };
        case 404:
          return {
            statusType: ResponseStatusType.NOT_FOUND,
            message: response.data.error_message
          };
        case 409:
          return {
            statusType: ResponseStatusType.CONFLICT,
            message: response.data.error_message
          };
        case 429:
          return {
            statusType: ResponseStatusType.TOO_MANY_REQUESTS,
            message: response.data.error_message
          };
        default:
          return {
            statusType: ResponseStatusType.REJECTED,
            message: response.data.error_message
          };
      }
    default:
      return {
        statusType: ResponseStatusType.UNKNOWN,
        message: response.data.error_message
      };
  }
}
