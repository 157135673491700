import { memo } from "react";

// Types
import { SpanProps } from "./all-typography.types";

// Styles
import { SpanStyles } from "./all-typography.styles";

const Span = ({ children, className, style }: SpanProps) => (
  <SpanStyles className={className} style={{ ...style }}>
    {children}
  </SpanStyles>
);

export default memo(Span);
