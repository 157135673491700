import TrailerMan from "./Trailer-manufacturers";
import TrucksMan from "./Truck-manufacturers";
import TrailerTypes from "./Trailer-types";
import Login from "./Login";
import Users from "./Users";
import Businesses from "./Business";
import PaymentSystem from "./Payment-system";
import Branches from "./Branches";

export {
  TrailerMan,
  TrucksMan,
  TrailerTypes,
  Login,
  Users,
  Businesses,
  PaymentSystem,
  Branches,
};
